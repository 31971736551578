@import './variables.scss';

.mat-button, .mat-flat-button, .mat-raised-button, a.mat-button, button.mat-button {
    &.mat-primary {
        color: #fff;
        background-color: $primary;
    }
}

.mat-drawer-container {
    background-color: white;
}

.mat-drawer-inner-container {
    // no idea why I need this here
    // does not seem to be working when placed in component style files
    // obnoxious
    overflow: visible !important;
}

.mat-drawer-content {
    min-height: 100vh;
}
