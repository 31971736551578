@use './variables.scss';

body {
    //font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    background: #fff;
    color: #272727;
    display: block;
}


a, .link {
    text-decoration: none;
    cursor: pointer;
    color: blue;
}

b {
    font-weight: 600;
}

a.hiding-anchor {
    display: none;

    &.hiding-anchor-showing {
        display: inline-block;
    }
}

.ellip {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top {
    .top-logo {
        height: 65px;
        width: 100%;
        // background-image: url('../assets/logo.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-color: #fff;
    }
}

.display-flex-align-center {
    display: flex;
    align-items: center;
}

.interface-content-container {
    .interface-content-title {
        padding-top: 25px;
        font-family: 'Roboto', sans-serif;
        line-height: 1.4;

        .title-text {
            display: flex;
            align-items: center;

            font-size: 27px;
            font-weight: 500;

            display: flex;
            align-items: center;

            .mat-icon {
                margin-right: 15px;
                font-size: 30px;
            }
        }

        .under-text {
            padding-top: 8px;
            font-weight: 400;
            color: #737373;
            font-size: 16px;
        }
    }



    .fetch-status {
        padding-top: 25px;
        font-size: 14px;
        font-weight: 500;
    }

    .interface-content {
        padding-top: 27px;
    }
}

